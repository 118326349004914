import { Box } from 'rebass';
import React from 'react';
import styled from 'styled-components';

import BodyText from '../components/body-text';
import Image from '../components/image';
import Layout from '../components/layout';
import PageHeading from '../components/page-heading';
import SEO from '../components/seo';

const ImageWrap = styled(Box)`
  width: 100%;
  max-width: 300px;
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    float: right;
  }
`;

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <PageHeading>About</PageHeading>
    <Box>
      <ImageWrap ml={[0, 0, 3]} mb={3}>
        <Image />
      </ImageWrap>
      <BodyText>
        Ian is on the Ontario Ministry of Labour&rsquo;s approved list of
        arbitrators, the Grievance Settlement Board, the roster of arbitrators of
        the Ontario Police Arbitration Commission and the list of arbitrators of
        the Canadian Federal Mediation and Conciliation Service. He also serves or
        has served as a Workload Resolution Arbitrator for a variety of Community
        Colleges.
      </BodyText>
    </Box>

    <BodyText>
      Ian was a full-time Vice-Chair of the Ontario Labour Relations Board from
      2004 to 2013, cross-appointed as a part-time member of the Human Rights
      Tribunal of Ontario from 2005 to 2013. From 1987 to 2004, Ian practiced
      labour and employment law.
    </BodyText>

    <BodyText>
      Ian was the Chair of the Board of Directors of the community legal clinic
      Injured Workers&rsquo; Consultants, the first Chair of the Workers&rsquo;
      Compensation Section of the Ontario Bar Association and a member of the
      Law Society of Upper Canada&rsquo;s Workers&rsquo; Compensation Specialist
      Certification Committee, the Chair of the Board of Directors of the
      Institute for Work &amp; Health and the Chair of the Board of Directors of
      Crossroads International.
    </BodyText>

    <BodyText>
      Ian is a member of the Ontario Labour Management Arbitrators&rsquo;
      Association and the Law Society of Ontario.
    </BodyText>
  </Layout>
);

export default AboutPage;
